<template>
    <div>
        <navbar />
        <div class="article-info">
            <div class="article-info-title">{{ info.title }}</div>
            <div class="article-info-view">
                <span class="article-info-view-left">{{ info.author }}</span>
                <span class="article-info-view-right">{{ info.publish_at }}</span>
            </div>

            <div class="_content">
                <p v-if="info && info.start_video">
                    <video
                        x5-video-player-type="h5"
                        x5-playsinline=""
                        playsinline=""
                        webkit-playsinline=""
                        :poster="(info && info.start_video_cover) || ''"
                        controls
                        style="width: 100%"
                        :src="info.start_video"
                    />
                </p>
                <div v-if="content" v-html="content" @click="clickImagePreview" />
                <p v-if="info && info.end_video">
                    <video
                        x5-video-player-type="h5"
                        x5-playsinline=""
                        playsinline=""
                        webkit-playsinline=""
                        :poster="(info && info.end_video_cover) || ''"
                        controls
                        style="width: 100%"
                        :src="info.end_video"
                    />
                </p>
            </div>
            <div class="article-info-view-bottom">
                <div>阅读量{{ info.browses_count }}</div>
                <div v-if="!info.is_liked" @click="likesClick" style="display: flex; justify-content: center; align-items: center">
                    <img src="../assets/images/icon_dianzan.png" class="site-image" />
                    <span class="site-text">赞</span>
                    <span style="margin-left: 5px">{{ info.likes_count }}</span>
                </div>
                <div v-if="info.is_liked" @click="likesClick" style="display: flex; justify-content: center; align-items: center">
                    <img src="../assets/images/dianzan.png" class="site-image" />
                    <span class="site-text">赞</span>
                    <span style="margin-left: 5px">{{ info.likes_count }}</span>
                </div>
            </div>
        </div>
        <!--    <div>哈哈</div>-->
        <div class="messages">
            <div class="messages-top">
                <div class="messages-top-input">
                    <van-field v-model="value" placeholder="写下你的留言" :autofocus="autofocus" @input="inputValue" />
                </div>
                <div class="messages-top-button" @click="sendMessage">确定</div>
            </div>
            <div class="messages-bottom" v-if="messageList.length !== 0">
                <div class="messages-bottom-title">精选留言</div>
                <van-list v-model="isLoading" :finished="finished" finished-text="我是有底线的" @load="onLoad" :error.sync="error" error-text="请求失败，点击重新加载" offset="100">
                    <div class="messages-content" v-for="item in messageList" :key="item.id">
                        <div class="messages-content-item">
                            <div class="left">
                                <img :src="item.user.avatar" class="left-img" />
                            </div>
                            <div class="right">
                                <div class="right-username">
                                    <div class="messages-username">{{ item.user.name || item.user.nickname }}</div>
                                    <img src="../assets/images/icon_zhiding.png" class="right-username-img" v-if="item.is_top === 1" />
                                    <div class="messages-username-label">
                                        <div v-if="item.can_delete" @click="deleteClick(item.id)">删除</div>
                                        <div v-if="item.can_delete" class="division" />
                                        <div style="display: flex; align-items: center" @click="autofocusClick(item.id)">
                                            <img src="../assets/images/message.png" class="messages-image" />
                                            <span>回复</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="right-content">{{ item.content }}</div>
                            </div>
                        </div>

                        <div class="messages-left" v-if="item.reply">
                            <div class="messages-reply-item">
                                <div class="left">
                                    <img src="../assets/images/icon_touxiangb.png" class="left-img" />
                                </div>
                                <div class="right">
                                    <div class="right-username">{{ item.reply_admin_user.name }}</div>
                                    <div class="right-content">{{ item.reply }}</div>
                                </div>
                            </div>
                        </div>

                        <div v-if="item.children">
                            <div class="messages-left" v-for="it in item.children" :key="it.id">
                                <div class="messages-reply-item">
                                    <div class="left">
                                        <img :src="it.user.avatar" class="left-img" />
                                    </div>
                                    <div class="right">
                                        <div class="right-username">
                                            <div class="right-username-name">{{ it.user.name }}</div>
                                            <div class="right-username-delete" v-if="it.can_delete" @click="deleteClick(it.id)">删除</div>
                                        </div>
                                        <div class="right-content">{{ it.content }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
            <div v-else class="null-view">暂无精选留言</div>
        </div>

        <div class="zao" v-if="autofocus" @click="zaoClick">
            <div class="bottom-div" v-if="autofocus">
                <div class="messages-top">
                    <div class="messages-top-input">
                        <van-field v-model="huifu" @click.stop="inputClick" placeholder="写下你的留言" autofocus="true" @input="inputChange" />
                    </div>
                    <div class="messages-top-button" @click="sendMessages">确定</div>
                </div>
            </div>
        </div>
        <van-image-preview v-model="previewShow" :images="images" :closeable="true"> </van-image-preview>
    </div>
</template>

<script>
import navbar from "../components/NavBar"
import { Dialog, Toast } from "vant"

export default {
    name: "articleInfo",
    data() {
        return {
            info: {},
            value: "",
            id: "",
            messageList: [],
            clearTimeSet: null,
            show: 0,
            articleId: "",
            content: "",
            huifu: "",
            messageId: "",
            autofocus: false,
            dianzan: false,
            currentPage: 1,
            per_page: null,
            page: 0,
            total: null,
            isLoading: false,
            finished: false,
            refreshing: false, //刷新
            error: false,
            previewShow: false,
            images: []
        }
    },
    components: { navbar },
    beforeDestroy() {
        clearInterval(this.clearTimeSet)
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === "selectAddr") {
            // from.meta.keepAlive = true;
        } else {
            // from.meta.keepAlive = false;
            this.removeKeepAliveCache()
        }
        next()
    },
    deactivated() {
        clearInterval(this.clearTimeSet)
    },
    activated() {
        this.info = {}
        this.messageList = []
        this.init()
    },

    // mounted() {
    //   this.init();
    // },
    methods: {
        clickImagePreview(data) {
            if (data.target.localName === "img") {
                const currentSrc = data.target.currentSrc
                const u = new URL(currentSrc)
                const whiteList = ["alioss.fuchs.com.cn", "fuchsconnect.fuchs.com.cn"]
                if (whiteList.includes(u.hostname)) {
                    this.images = [currentSrc]
                    this.previewShow = true
                }
            }
        },
        inputClick() {},
        zaoClick() {
            this.autofocus = false
            this.huifu = ""
        },
        inputChange(v) {
            this.huifu = v
        },
        sendMessages() {
            if (!this.huifu) {
                return Toast("请输入留言")
            } else {
                this.$http.post(`/articles/${this.id}/messages`, { content: this.huifu, message_id: this.messageId }).then(() => {
                    Dialog.alert({
                        message: "留言成功,被评为精选后展示"
                    })
                    this.autofocus = false
                    this.huifu = ""
                    this.messagesList(this.$route.query.id)
                })
            }
        },
        autofocusClick(id) {
            this.autofocus = true
            this.messageId = id
        },
        deleteClick(id) {
            Dialog.confirm({
                title: "提示",
                message: "确定删除吗？"
            }).then(() => {
                this.$http.delete(`articles/${this.id}/messages/${id}`).then(() => {
                    Dialog.alert({
                        message: "删除成功"
                    })
                    this.messageList = []
                    this.messagesList(this.$route.query.id)
                })
            })
        },
        likesClick() {
            this.$http.post(`articles/${this.id}/like`).then(() => {
                this.init()
            })
        },
        async init() {
            await this.getWXConfig()
            const ua = window.navigator.userAgent
            if (/MicroMessenger/i.test(ua) && /wxwork/i.test(ua)) {
                await this.getArticleById(this.$route.query.id, "work_share", this.$route.query.preview_at)
            } else if (/MicroMessenger/i.test(ua)) {
                await this.getArticleById(this.$route.query.id, "wechat", this.$route.query.preview_at)
            } else {
                await this.getArticleById(this.$route.query.id, "workbench", this.$route.query.preview_at)
            }
            this.id = this.$route.query.id
            await this.messagesList(this.$route.query.id)
            await this.setTime()
            this.$http.put(`articles/${this.id}/update_browse`)
        },
        getArticleById(value, type, time) {
            this.$http
                .get(`/articles/${value}?source=${type}${time ? "&preview_at=" + time : ""}`)
                .then(res => {
                    this.articleId = res.data.data.id

                    const html = res.data.data.content || ""
                    const div = document.createElement("div")
                    div.innerHTML = html

                    const nodeListOf = div.querySelectorAll("img")
                    if (div.querySelectorAll("section")[0]) {
                        const sectionNode = div.querySelectorAll("section")[0]
                        sectionNode.style.width = "100%"
                        sectionNode.style.maxWidth = "100%"
                        nodeListOf.forEach(item => {
                            item.style.width = "auto"
                            item.style.maxWidth = "100%"
                        })
                    }
                    this.info = res.data.data
                    // if (res.data.data.start_video_cover && res.data.data.start_video) {
                    //   this.content = `<div>&nbsp;</div><p><video x5-video-player-type="h5" x5-playsinline="" playsinline="" webkit-playsinline="" poster="${res.data.data.start_video_cover}" controls style="width: 100%" src="${res.data.data.start_video}" /></p>`
                    //       + div.innerHTML +
                    //       `<div>&nbsp;</div><p><video x5-video-player-type="h5" x5-playsinline="" playsinline="" webkit-playsinline="" poster="${res.data.data.end_video_cover}" controls style="width: 100%" src="${res.data.data.end_video}" /></p>`
                    // } else {
                    //   this.content = div.innerHTML;
                    // }
                    this.content = div.innerHTML

                    // eslint-disable-next-line no-undef
                    wx.error(() => {
                        location.reload()
                    })
                    // eslint-disable-next-line no-undef
                    wx.ready(() => {
                        if (res.data.data.can_share === 0) {
                            // eslint-disable-next-line no-undef
                            wx.hideMenuItems({
                                menuList: ["menuItem:share:wechat", "menuItem:share:appMessage"]
                            })
                        } else {
                            // eslint-disable-next-line no-undef
                            wx.showMenuItems({
                                menuList: ["menuItem:share:wechat", "menuItem:share:appMessage"]
                            })
                        }
                    })
                    this.shareAppMessage(res.data.data)
                })
                .catch(() => {})
        },
        inputValue(v) {
            this.value = v
        },
        sendMessage() {
            if (!this.value) {
                return Toast("请输入留言")
            } else {
                this.$http
                    .post(`articles/${this.$route.query.id}/messages`, { content: this.value })
                    .then(() => {
                        Dialog.alert({
                            message: "留言成功,被评为精选后展示"
                        })
                        this.value = ""
                    })
                    .catch(() => {
                        Dialog.alert({
                            message: "留言失败，请稍后再试"
                        })
                    })
            }
        },
        onLoad() {
            console.log("???")
            setTimeout(() => {
                this.messagesList(this.$route.query.id, this.page + 1)
            }, 500)
        },
        messagesList(id, page = 1) {
            this.isLoading = true
            this.$http
                .get(`articles/${id}/messages?page=${page}&per_page=10`)
                .then(res => {
                    if (res.data.meta.to === res.data.meta.total) {
                        this.finished = true
                    } else {
                        this.finished = false
                    }
                    if (Array.isArray(res.data.data)) {
                        const list = res.data.data.filter(item => {
                            return !this.messageList.find(it => it.id === item.id)
                        })
                        this.messageList = this.messageList.concat(list)
                        console.log(this.messageList)
                    }
                    this.per_page = res.data.meta.per_page
                    this.total = res.data.meta.total
                    this.page = res.data.meta.current_page
                })
                .finally(() => {
                    this.isLoading = false
                })
                .catch(() => {
                    this.error = true
                })
        },
        setTime() {
            if (this.clearTimeSet) {
                clearInterval(this.clearTimeSet)
                this.clearTimeSet = null
            }
            this.clearTimeSet = setInterval(() => {
                this.$http.put(`articles/${this.id}/update_browse`)
            }, 3000)
        },
        shareAppMessage(v) {
            let that = this
            // eslint-disable-next-line no-undef
            wx.ready(() => {
                const ua = window.navigator.userAgent
                const URI = new URL(window.location.href)
                URI.search = ""

                console.log("URI", URI.toString())
                if (/MicroMessenger/i.test(ua)) {
                    if (/wxwork/i.test(ua)) {
                        console.log("企业微信")
                        // eslint-disable-next-line no-undef
                        wx.onMenuShareAppMessage({
                            title: v.title, // 分享标题
                            desc: v.summary, // 分享描述
                            link: URI.toString(), // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
                            imgUrl: v.thumbnail, // 分享图标
                            success: function () {
                                // 用户确认分享后执行的回调函数
                                console.log("分享了")
                                that.$http.post(`/articles/${that.id}/shared`).then(() => {
                                    Dialog.alert({
                                        title: "分享成功"
                                    })
                                })
                            },
                            cancel: function () {
                                // 用户取消分享后执行的回调函数
                                console.log("取消了")
                            }
                        })
                        // eslint-disable-next-line no-undef
                        wx.onMenuShareTimeline({
                            title: v.title, // 分享标题
                            link: URI.toString(), // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
                            imgUrl: v.thumbnail, // 分享图标
                            success: function () {
                                // 用户确认分享后执行的回调函数
                                that.$http.post(`/articles/${that.id}/shared`).then(() => {
                                    Dialog.alert({
                                        title: "分享成功"
                                    })
                                })
                            },
                            cancel: function () {
                                // 用户取消分享后执行的回调函数
                            }
                        })
                    } else {
                        console.log("微信")
                        // eslint-disable-next-line no-undef
                        wx.onMenuShareAppMessage({
                            title: v.title, // 分享标题
                            desc: v.summary, // 分享描述
                            link: URI.toString(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: v.thumbnail, // 分享图标
                            success: function () {
                                // 设置成功
                                // that.$http.post(`/articles/${that.id}/shared`).then(() => {
                                //   Dialog.alert({
                                //     title: "分享成功",
                                //   })
                                // })
                            }
                        })
                        // eslint-disable-next-line no-undef
                        wx.onMenuShareTimeline({
                            title: v.title, // 分享标题
                            link: URI.toString(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: v.thumbnail, // 分享图标
                            success: function () {
                                // that.$http.post(`/articles/${that.id}/shared`).then(() => {
                                //   Dialog.alert({
                                //     title: "分享成功",
                                //   })
                                // })
                            }
                        })
                    }
                }
            })
        },
        getWXConfig() {
            if (/MicroMessenger/i.test(navigator.userAgent)) {
                let type = "official_account"
                if (/wxwork/i.test(navigator.userAgent)) {
                    console.log("企业微信授权")
                    type = "work"
                }
                type = "work"
                // console.log("qwq", window.location.href.replace(/(http|https):\/\//, ""))
                this.$http
                    .get(`/auth/share_config`, {
                        params: {
                            current_url: window.location.href,
                            type
                        }
                    })
                    .then(res => {
                        // eslint-disable-next-line no-undef
                        wx.config(res.data)
                        // eslint-disable-next-line no-undef
                        wx.error(function (res) {
                            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                            console.log("====================")
                            console.log("配置失败")
                            console.log(res)
                            console.log("====================")
                        })
                    })
            }
        },
        removeKeepAliveCache() {
            if (this.$vnode && this.$vnode.data.keepAlive && this.$vnode.parent) {
                const tag = this.$vnode.tag
                let caches = this.$vnode.parent.componentInstance.cache
                let keys = this.$vnode.parent.componentInstance.keys
                for (let [key, cache] of Object.entries(caches)) {
                    if (cache.tag === tag) {
                        if (keys.length > 0 && keys.includes(key)) {
                            keys.splice(keys.indexOf(key), 1)
                        }
                        delete caches[key]
                    }
                }
            }
            this.$destroy()
        }
    }
}
</script>
<style lang="less">
.article-info {
    margin-top: 200px;
    max-width: 100vw;
    padding: 0 76px;

    // ._content {
    //     line-height: 0;
    // }

    section {
        box-sizing: border-box;
    }

    &-view {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 56px;

        &-left {
            font-size: 36px;
            font-weight: 400;
            color: #004f9f;
            margin-right: 42px;
        }

        &-right {
            font-size: 36px;
            font-weight: 400;
            color: #787878;
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            font-size: 36px;
            font-weight: 400;
            color: #787878;
            padding-bottom: 56px;
            padding-top: 56px;
        }
    }

    .site-image {
        width: 50px;
        height: 50px;
    }

    .site-text {
        font-size: 30px;
        font-weight: 400;
        color: #7a7a7a;
        margin-left: 17px;
    }

    &-title {
        font-size: 48px;
        font-weight: 400;
        color: #242424;
        margin-bottom: 26px;
        line-height: 80px;
    }
}

.messages {
    background: rgba(238, 237, 237, 1);
    padding: 52px 97px;

    &-top {
        display: flex;
        justify-content: center;
        align-items: center;

        &-input {
            width: 887px;
            height: 81px;
            background: #d3d1d1;
        }

        &-button {
            width: 200px;
            height: 81px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #d3d1d1;
            border-radius: 5px;
            margin-left: 10px;
            font-weight: 400;
            color: #787878;
        }
    }

    &-bottom {
        margin-top: 94px;

        &-title {
            font-size: 36px;
            font-weight: 400;
            color: #787878;
        }

        &-content {
            margin-bottom: 58px;
        }
    }
}

.null-view {
    margin-top: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b0b1b9;
}

.messages-content-item {
    display: flex;
    margin-top: 58px;

    .left {
        flex-shrink: 0;

        &-img {
            width: 92px;
            height: 92px;
        }
    }

    .right {
        margin-left: 35px;
        width: 100%;

        .messages-username {
            font-size: 36px;
            color: #787878;
        }

        .messages-username-label {
            display: flex;
            align-content: center;
            margin-left: auto;
            font-size: 36px;
            color: #828282;

            .division {
                margin: 0 32px;
                width: 2px;
                height: 33px;
                background: #a4a4a4;
                border-radius: 1px;
            }
        }

        .messages-image {
            width: 35px;
            height: 30px;
            margin-right: 23px;
        }

        &-username {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            flex: 1;

            &-img {
                width: 71px;
                height: 37px;
                margin-left: 35px;
            }
        }

        &-content {
            font-size: 40px;
            color: #343333;
        }
    }
}

.messages-reply-item {
    display: flex;
    margin-top: 58px;

    .left {
        flex-shrink: 0;
        &-img {
            width: 70px;
            height: 70px;
        }
    }

    .right {
        margin-top: 8px;
        margin-left: 22px;
        width: 100%;

        .messages-username {
            font-size: 36px;
            color: #787878;
        }

        .messages-username-label {
            display: flex;
            align-content: center;
            margin-left: auto;
            font-size: 30px;
            color: #828282;
        }

        &-username {
            display: flex;
            align-items: center;
            margin-bottom: 28px;
            flex: 1;

            &-name {
                font-size: 36px;
                color: #787878;
            }

            &-delete {
                margin-left: auto;
                font-size: 30px;
                color: #828282;
            }
        }

        &-content {
            font-size: 40px;
            color: #343333;
        }
    }
}

.messages-left {
    padding-left: 100px;
    margin-top: 36px;
}

.van-cell,
.van-field {
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
}

.van-field__body {
    height: 100%;
}

.van-field__control {
    font-size: 36px;
    font-weight: 400;
    color: #787878;
}

.van-field__control::placeholder {
    font-size: 36px;
    font-weight: 400;
    color: #787878;
}

img {
    max-width: 100%;
}

video {
    max-width: 100%;
    min-width: 100%;
}

.van-dialog__confirm,
.van-dialog__header,
.van-dialog__confirm:active {
    color: rgba(0, 79, 159, 1);
}

.bottom-div {
    position: fixed;
    bottom: 100px;
    left: 0;
    z-index: 9999;
}

.zao {
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.5);
    position: fixed;
    top: 0;
    left: 0;
}
</style>
